<div class="container-fluid">
    <div class="row">
        <div class="col-2 border border-secundary bg-white">
            <div class="d-block my-5">
                <nav class="navbar navbar-expand-lg">
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-bar list-unstyled ps-0" style="width: 100%;">
                        <li class="nav-item mb-2 py-2">
                        <a href="/admin" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grid-fill" viewBox="0 0 16 16">
                            <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5z"/>
                            </svg> Dashboard
                        </a>
                        </li>
                
                        <li class="nav-item mb-2 py-2">
                            <a  href="/notificacion" class="btn rounded-2 text-muted fw-light fs-6 menu text-start collapsed">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-right-fill" viewBox="0 0 16 16">
                                <path d="M14 0a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/>
                            </svg> Notificaciones
                            </a>
                        </li>
                        <li class="nav-item mb-2 py-2">
                            <a href="/equipo" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                            </svg> Equipo
                            </a>
                        </li>
                
                        <li class="nav-item mb-2 py-2">
                            <a href="/evaluacion" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                            </svg> Evaluaciones
                            </a>
                        </li>
                
                        <li class="nav-item mb-2 py-2">
                            <a href="/programas" class="btn rounded-2 text-muted fw-light fs-6 menu text-start collapsed">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mortarboard-fill" viewBox="0 0 16 16">
                                <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917z"/>
                                <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466z"/>
                            </svg> Programa
                            </a>
                        </li>
                
                        <li class="nav-item mb-2 py-2">
                            <a href="/configuracion" class="btn rounded-2 text-muted fw-light fs-6 menu text-start collapsed">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16">
                                <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
                            </svg> Configuracion
                            </a>
                        </li>
                        
                        <li class="border-top border-light d-block my-3 mx-0"></li>
                        
                            <li class="nav-item mb-2 py-2">
                            <a href="/ayuda" class="btn rounded-2 text-muted fw-light fs-6 menu text-start  collapsed p-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-lg" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14"/>
                                </svg> Ayuda
                            </a>
                            </li>
                            <li class="nav-item mb-2 py-2">
                            <a href="/logout" class="btn rounded-2 text-muted fw-light fs-6 menu text-start  collapsed p-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
                                <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                                </svg> Salir
                            </a>
                            </li>
                            <li class="border-top border-light d-block my-3 mx-0"></li>
                        
                    </ul>
                    </div>
                </nav>
            </div>
            
        </div>
        <div class="col-10">
            <nav class="navbar navbar-expand-lg navbar-light bg-white border boder-bottom">
                <div class="container">
                  <div class="card-body rounded-3">
                    <h3 class="text-start font-semibold" style="font-size: 1.3rem;">Bienvenido, {{ content.user.fname }} {{ content.user.lname }}</h3>
                    <span class="text-start font-light text-sm text-muted my-1">{{this.dateTime}}</span> 
                  </div>
                </div>
            </nav>
            <div class="card border-0 mt-2">          
                <div class="card-body">                
                      <div class="row">
                          <div class="col"> 
                              <span class="fs-4 text-dark-text-start fw-bold">Inscripciones</span>
                          </div>
                          <div class="col"> 
                              <div class="d-flex justify-content-end">
                                
                                  
                                  
                                <div class="d-flex gap-2">
                                 <!-- Something is here -->
                                  
                                </div>
                              </div>
                          </div>
                      </div>
        
                      <div class="row mt-2">
                          <div class="col">
                              <p class="text-sm text-muted ">Total de Inscripciones: {{ content.totalI}}</p>
                              <p class="text-sm text-muted fw-semibold  mt-2"></p>
                          </div>
                      </div>
                      <div class="row mt-2 rounded-3">
                          <div class="col">
                   
                        <div class="col border border-1">
                            <div class="row row-cols-1 row-cols-md-1 fw-bold m-0">
                              <p class="fw-bold text-center">Inscripciones de Admisición</p>
                            </div>
                            <div class="d-flex table-responsive" style="font-family:sans-serif;">
                                <table class="table table-bordered table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th style="font-size: 0.6rem; text-align: center;">Código</th>
                                            <th style="font-size: 0.6rem; text-align: center;">Maestrías</th>
                                            <th style="font-size: 0.6rem; text-align: center;">Cant. Inscripciones</th>
                                            <th style="font-size: 0.6rem; text-align: center;"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                        <tr *ngFor="let m of content.JsonData;">
                                            <td class="fw-bold" style="font-size: 0.6rem;">{{ m.codigo }} </td>
                                            <td class="fw-bold" style="font-size: 0.6rem;">{{ m.maestria }} </td>
                                            <td class="fw-bold" style="font-size: 0.6rem;">{{ m.inscripcionCount }}</td>
                                            <td class="fw-bold" style="font-size: 0.6rem;">
                                                
                                                <button class="btn btn-sm h-50 px-3 w-100 rounded-3 mx-2" style="background-color: #f8ece3; color: #f47b20;" (click)="openViewInscripcionModal(m.maestria,  content.dataIN.fname + ' ' + content.dataIN.lname, content.dataIN.nid, content.dataIN.tanda);"> 
                                                    &nbsp; Ver</button>
                                            </td>
                                         
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
        </div>

    </div>
</div>